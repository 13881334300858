export function useIsInDialog(element: Ref<HTMLElement | null>) {
  const isInDialog = ref(false);

  watch(
    element,
    (el) => {
      if (el) {
        isInDialog.value = !!el.closest('#dialogRoot');
      }
    },
    { immediate: true },
  );

  return isInDialog;
}
